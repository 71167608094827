<template>
  <div class="text-center">
    <img :src="require('@/assets/images/icons/loading-gears.gif')" alt="Loading.." />
  </div>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style scoped></style>
