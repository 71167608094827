<template>
  <div>
    <table id="stats">
      <thead>
        <tr>
          <th style="max-width: 50px !important">Character</th>
          <th>Clan</th>
          <th>Enchant</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(epic_item, i) in top" :key="i">
          <tr>
            <td>
              <span
                :class="{
                  male: epic_item.sex === '0',
                  female: epic_item.sex === '1',
                }"
                class="tooltip overflow_wrap char_name_max_width bold"
                translate="no"
                >{{ epic_item.char_name }}
                <span class="tooltiptext"
                  >Online time: {{ this.get_online_time(epic_item.onlinetime) }}</span
                >
              </span>
              <hr />
              <span style="font-size: 0.8em" translate="no"
                >{{ epic_item.class_name }}, {{ epic_item.level }}</span
              >
            </td>
            <td class="text-center overflow_wrap clan_max_width" translate="no">
              {{ epic_item.clan_name }}
            </td>
            <td class="text-center bold">{{ epic_item.enchant }}</td>
            <td class="padding_status">
              <img
                :src="require('@/assets/images/icons/ico_status_salad.png')"
                alt="Online"
                v-if="epic_item.online === '1'"
              />
              <img
                :src="require('@/assets/images/icons/ico_status_red.png')"
                alt="Offline"
                v-if="epic_item.online === '0'"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'EpicItemTable',
  props: {
    top: Object,
  },
  methods: {
    get_online_time(time) {
      let online_time_h;
      let x = time / 60 / 60 - 0.5;
      if (x <= 0) {
        online_time_h = 0;
      } else {
        online_time_h = Math.round(x);
      }
      let online_time_m = Math.round((time / 60 / 60 - online_time_h) * 60);

      if (online_time_h === 0 && online_time_m !== 0) {
        return `${online_time_m} min.`;
      }

      if (online_time_m === 0 && online_time_h !== 0) {
        return `${online_time_h} hrs.`;
      }

      return `${online_time_h} hrs. ${online_time_m} min.`;
    },
  },
};
</script>

<style scoped>
table#stats td,
th {
  border-collapse: collapse;
  border: 1px solid #111;
}

.padding_status {
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.overflow_wrap {
  overflow-wrap: anywhere !important;
  white-space: pre-wrap !important;
}

.clan_max_width {
  max-width: 85px !important;
}

.char_name_max_width {
  max-width: 104px !important;
}
</style>
