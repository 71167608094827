<template>
  <table id="stats">
    <thead>
      <tr>
        <th>#</th>
        <th style="max-width: 50px !important">Character</th>
        <th>Clan</th>
        <th>
          <span class="_pvp">PVP</span> /
          <span class="_pk">PK</span>
        </th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(player, i) in top" :key="i">
        <tr>
          <td class="text-center">{{ player.rank }}</td>
          <td>
            <span
              :class="{ male: player.sex === '0', female: player.sex === '1' }"
              class="tooltip fix-wrap-char-title bold"
              translate="no"
              >{{ player.char_name }}
              <span class="tooltiptext">Online time: {{ get_online_time(player.onlinetime) }}</span>
            </span>
            <hr />
            <span style="font-size: 0.8em" translate="no"
              >{{ player.class_name }}, {{ player.level }}</span
            >
          </td>
          <td class="text-center fix-wrap-clan-title">
            {{ player.clan_name }}
          </td>
          <td class="text-center bold">
            <span class="_pvp">{{ comma_seperated(player.pvpkills) }}</span>
            <hr />
            <span class="_pk">{{ comma_seperated(player.pkkills) }}</span>
          </td>
          <td :class="player.online === '0' ? 'ico_status_off' : 'ico_status_on'"></td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'PVP',
  props: {
    top: Object,
  },
  methods: {
    get_online_time(time) {
      let online_time_h;
      let x = time / 60 / 60 - 0.5;
      if (x <= 0) {
        online_time_h = 0;
      } else {
        online_time_h = Math.round(x);
      }
      let online_time_m = Math.round((time / 60 / 60 - online_time_h) * 60);
      return `${online_time_h} hrs. ${online_time_m} min.`;
    },
    comma_seperated(num) {
      return Number(num).toLocaleString('en-US');
    },
  },
};
</script>

<style scoped></style>
